import { v4 as uuidv4 } from 'uuid'
import TypesetText from '~/editor/typeset-text/typeset-text'
import { dbRowToTranslationVersion } from '~/helpers/database-conversions'
import { createTypesetText } from '~/services/current-document/typeset-texts'
import { InitTypesetTextProps } from '~/services/current-document/loading/initial-typeset-text.interfaces'
import { TypesetTextBoundingBox } from '~/editor/typeset-text/typeset-text.interfaces'
import { referenceWidth } from '~/services/static/document'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'
import { Point } from 'fabric'

const createInitialTypesetTexts = async ({ currentDocument, chapterData, translationsData, setIsLoading }: InitTypesetTextProps) => {
  setLoadingState({
    loading: true,
    message: translations().loading.default
  })
  currentDocument.loadPages(chapterData.pages.clean).then(() => {
    // Reduce to unique translation uuids
    const uniqueIds = translationsData
      .reduce<string[]>((result, entry) => {
        if (!result.includes(entry.entry_id)) {
          result = result.concat(entry.entry_id)
        }
        return result
    }, [])

    // Group versions using their parent translation uuid
    // Return last version
    const uniqueTranslations = uniqueIds.map(id => {
      const versions = translationsData
        .filter(entry => entry.entry_id === id)
        .map(entry => dbRowToTranslationVersion(entry))
        .sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()))
      return versions[versions.length - 1]
    }).filter(entry => !entry.isOnomatopoeia)

    // For each translation entry, create a Typesetting entry
    uniqueTranslations.forEach((entry) => {
      // Get the page where the translation is
      const pageIndex = chapterData.pages.original.findIndex(page => page.id === entry.pageId)
      const page = currentDocument.pages[pageIndex]

      if (page && entry.startPoint !== null && entry.endPoint !== null) {
        const relativeWidth = Math.abs(entry.startPoint.x - entry.endPoint.x)
        const absoluteWidth = relativeWidth * referenceWidth

        const translationCenterX = entry.startPoint.x + (relativeWidth / 2)
        const translationCenterY = entry.startPoint.y
        const startPoint = new Point({
          x: translationCenterX,
          y: translationCenterY,
        })

        const boundingBox: TypesetTextBoundingBox = {
          startPoint,
          relativeWidth,
          absoluteWidth
        }

        const typesetText = new TypesetText({
          id: uuidv4(),
          text: entry.translatedText,
          boundingBox,
          page,
          date: new Date().toUTCString(),
          settings: currentDocument.settings,
          translationId: entry.id
        })
        
        createTypesetText(typesetText)
        typesetText.onChange()
        typesetText.resize()
        
        setTimeout(() => {
          typesetText.applyStyles(typesetText.styles, true)
        }, 1000)
      }
    })
  }).finally(() => {
    setLoadingState({
      loading: false
    })
    setIsLoading(false)
  })
}

export {
  createInitialTypesetTexts
}